const theme = {
  sidePanelBg: '#FFFFFF !important', // Color del panel inferior
  titleTextColor: '#626262', // Color del título Layer
  inputBorderColor: '#999999 !important', // Color del borde de los inputs
  secondaryInputBorderColor: '#999999 !important', // Color del borde de los inputs de los campos Layer Blending y Map Overlay Blending
  selectBorderColor: '#999999 !important', // Color del borde de los selects
  sidePanelHeaderBg: '#FFFFFF', // Color del encabezado del panel
  ctaBtnBgd: '#626262 !important', // Color del botón de guardar
  layerConfigGroupMarginBottom: '#999999 !important', // Color del borde del encabezado del panel
  sidePanelHeaderBorder: '#999999 !important', // Color del borde del encabezado del panel
  subtextColorActive: '#000000 !important', // Color de los iconos cuando se seleccionan
  tooltipBg: '#999999 !important', // Color de los mensajes que salen cuando nos colocamos encima de los iconos
  tooltipColor: '#ffffff !important', // Color de la letra de los mensajes que salen cuando nos colocamos encima de los iconos
  dropdownListBgd: '#626262 !important', // Color del desplegable cuando damos click en los campos Layer Blending y Map Overlay Blending
  textColorHl: '#ffffff', // Color de los iconos cuando pasamos por encima de ellos
  inputBgd: '#ffffff', // Color del input de los campos Layer Blending y Map Overlay Blending
  inputBgdHover: '#ffffff !important', // Color del input de los campos cuando pasas por encima Layer Blending y Map Overlay Blending
  inputBgdActive: '#ffffff !important', // Color del input de los campos Layer Blending y Map Overlay Blending cuando se seleccionan
  dropdownListHighlightBg: '#999999', // Color de las opciones de Layer Blending y Map Overlay cuando te paras encima
  panelBackground: '#e9e9e9', // Color de los paneles de configuración en interactions y base map con mapstyle
  panelContentBackground: '#e9e9e9',
  panelBackgroundHover: '#999999', // Color de los paneles de configuración en base map con mapstyle cuando pasas por encima el cursor
  secondaryInputBgd: '#ffffff', // Color del botón absolute esta dentro de tooltip en interactions
  secondaryInputBgdActive: '#626262',
  secondaryInputBgdHover: '#626262 !important',
  panelActiveBg: '#999999',
  textColor: '#626262', // Color de la letra en las opciones dentro de base map y interactions
  primaryBtnBgd: '#626262 !important', // Color de los botones
  primaryBtnColor: '#FFFFFF !important',
  primaryBtnBgdper: '#626262', // Color de fondo de los botones personalizados, que no tenga important
  primaryBtnColorper: '#FFFFFF', // Color de texto los botones personalizados, que no tenga important
  primaryDivBgdper: '#999999',
  primaryBtnActColor: '#FFFFFF !important',
  primaryBtnBgdHover: '#626262',
  mapPanelHeaderBackgroundColor: '#FFFFFF !important',
  mapPanelBackgroundColor: '#FFFFFF!important',
  labelColorActive: '#FFFFFF !important',
  selectActiveBorderColor: '#626262',
  labelColor: '#626262 !important', // Color de algunos iconos y letras pequeñas
  labelColorLT: '#626262 !important', // Color de documentos dentro de add data
  subtextColor: '#999999; !important', // Color del share
  switchTrackBgdActive: '#626262 !important', // Color del switch en interactions
  switchTrackBgd: '#D3D8E0 !important',
  switchBtnBgd: '#626262 !important',
  secondaryBtnBgd: '#626262 !important', // Botón add data
  checkboxBoxBgdChecked: '#FFFFFF !important',
  layerPanelToggleOptionColor: '#FFFFFF !important',
};

export default theme;
