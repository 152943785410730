import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "./button";
import {
  addDataToMap,
  loadFiles,
  MapStyleActions,
  toggleModal,
  startExportingImage,
} from "@kepler.gl/actions";

import { injectComponents, PanelHeaderFactory } from "@kepler.gl/components";
import KeplerGlSchema from "@kepler.gl/schemas";
import theme from "./theme";
import downloadJsonFile from "./file-download";

import DownloadIcon from "@mui/icons-material/Download";
import ImageIcon from "@mui/icons-material/Image";

import { v4 as uuidv4 } from "uuid";
import ExportMap from "./exportMap";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { processKeplerglJSON } from "@kepler.gl/processors";
import Loading from "./assets/map-loading.json";
import mapStyleJson from "./assets/map-styles.json";
import {
  Box,
 
  Dialog,
  DialogContent,
 
  Typography,
  LinearProgress,
} from "@mui/material";
import Lottie from "react-lottie";

import keycloak from "./keycloak-config";
import { getJsonItem } from "./getJsonItem";

import { EXPORT_IMAGE_ID } from "@kepler.gl/constants";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
  rendererSettings: {
    clearCanvas: true,
    progressiveLoad: true,
    preserveAspectRatio: "xMidYMid meet",
  },
};

const CustomHeader = ({ showExportImageModal }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    <div>
      <p style={{ color: theme.labelColor, margin: "3px 0px 1px 8px" }}>
        <b>Maps Analytics</b>
      </p>
      <p style={{ color: theme.labelColor, fontSize: "10px", margin: "0px 8px" }}>
        2024.3.0.0
      </p>
    </div>
  </div>
);

// Create a factory for custom header
const myCustomHeaderFactory = (PanelHeader) => (props) => (
  <CustomHeader {...props} showExportImageModal={props.showExportImageModal} />
);

// Inject custom header into Kepler.gl,
const KeplerGl = injectComponents([
  [PanelHeaderFactory, myCustomHeaderFactory],
  // [FileUploadFactory, myCustomFileUploadFactory],
]);

const MAPBOX_TOKEN = process.env.MAPBOX_TOKEN;
const API_URL = process.env.API_URL;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlMap: "",
      showAlert: false,
      alertMessage: "",
      alertResponse: "",
      progress: 0,
      loading: { bool: false, msg: "" },
    };

    this.timer = null;
  }

  setLoading({ bool, msg }) {
    this.setState((state) => ({
      ...state,
      loading: { ...state.loading, bool, msg },
    }));
  }

  calculateRemainingTime = () => {
    const { progress } = this.state;

    if (progress === 0) {
      return "Calculating...";
    }

    if (progress === 100) {
      return "Cargando...";
    }

    const elapsedTimeInSeconds = (Date.now() - this.startTime) / 1000;

    const estimatedTotalTimeInSeconds = elapsedTimeInSeconds / (progress / 100);
    const remainingTimeInSeconds =
      estimatedTotalTimeInSeconds - elapsedTimeInSeconds;

    const minutes = Math.floor(remainingTimeInSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(remainingTimeInSeconds % 60)
      .toString()
      .padStart(2, "0");

    return `${minutes}' ${seconds}''`;
  };

  changeStyleMap() {
    const mapStyles = mapStyleJson;
    console.log("changeStyleMap----", mapStyles);
    const defaultStyleId = "dark-matter";
    if (mapStyles.length > 0) {
      mapStyles.forEach((style) => {
        const mapboxStyle = {
          id: style.name,
          style: style.mapStyle,
          label: style.name,
          icon: style.icon,
          layerGroups: [],
          visible: true,
          url: style.mapStyle,
          token: MAPBOX_TOKEN,
        };
        this.props.dispatch(
          MapStyleActions.loadMapStyles({ [style.name]: mapboxStyle })
        );
      });

      const firstStyle = mapStyles[0].name || defaultStyleId;
      this.props.dispatch(MapStyleActions.mapStyleChange(firstStyle));
    } else {
      this.props.dispatch(MapStyleActions.mapStyleChange(defaultStyleId));
    }

    console.log("Styles loaded", this.props);
  }

  componentDidMount() {
    const message = { type: "FRAME", text: "Frame cargado" };
    // Envía el mensaje a la página padre
    window.parent.postMessage(message, "*");
    this.changeStyleMap();
    /* const {
      params: { id, provider } = {},
      location: { query = {} } = {},
    } = this.props;

    const cloudProvider = CLOUD_PROVIDERS.find((c) => c.name === provider);
    if (cloudProvider) {
      this.props.dispatch(
        loadCloudMap({
          loadParams: query,
          provider: cloudProvider,
          onSuccess: onLoadCloudMapSuccess,
        })
      );
      return;
    } */

    const dynamicURLPromise = new Promise((resolve, reject) => {
      console.time("listener");
      const messageHandler = (event) => {
        if (event.source !== parent) return;
        if (event.data.type === "dynamicURL") {
          const dynamicURL = event.data.url;
          console.log("dynamicURL", dynamicURL, event.data);
          removeEventListener("message", messageHandler);
          if (dynamicURL) resolve(dynamicURL);
          else reject("url not obtained");
          console.timeEnd("listener");
          console.log();
          changeStyleMap();
        }
      };
      window.addEventListener("message", messageHandler);
    });
    console.time("request");

    dynamicURLPromise.then((dynamicURL) => {
      this.startTime = Date.now();
      this.setLoading({ bool: true, msg: "Loading Map" });
      const name = dynamicURL
        .split("/")
        .pop()
        .split("?")[0];

      this.setState({
        urlMap: name,
      });

      getJsonItem(dynamicURL, {
        onProgress: ({ fraction }) => {
          this.setState({ progress: Math.round(fraction * 100) });
        },
      })
        .then((datageo) => {
          try {
            const keplerGL = processKeplerglJSON(datageo);
            this.props.dispatch(addDataToMap(keplerGL));
          } catch (error) {
            const myblob = new Blob([JSON.stringify(datageo)]);
            const files = new File([myblob], name);
            this.props.dispatch(loadFiles([files]));
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          clearInterval(this.timer);
          this.setLoading({ bool: false, msg: "" });
          console.timeEnd("request");
        });
    });

    console.log("urlMap", this.state.urlMap);

    console.log(this.props);

    // ? getJsonItem(
    // ?   "https://nuv-dev-maps-analytics.s3.amazonaws.com/a9f4f009-b19b-4e9b-89c0-f7722233451d.map.json?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEgaCXVzLWVhc3QtMSJHMEUCIQDtC0TLIrUfSY7G6g2SRXPpw%2FQzFiimZlolxjZIilFfMwIgLo%2Fe45J7Xo3XETfehfi4krEC8GuwRv%2FGXI3HM1Bcj%2F8qmAQIsP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw4NzQ2NDE5MTI3NzciDFRvZ2las3H7c0s7TSrsA8ID2Z8r7Rt6YopqGPNt%2Fzi0fmOVw%2FzftzNTXiY4k%2Bu0w3FKqXBzzg95cEXutVnum5rkC3hj7HyVVphl30jw2ul5YpHyvD09tv16uvlWGLc2j0dRzq4TOgyN3DwpqXmTfvPwENq8QKJMOvmne5LNHDU%2Bd3faVwK7aaTOHRgB6HdcohuuEwR8O6Dr5iWbmJ9KH%2FSJOzgVbb1y0g7xD8YmYnB21H67utWbCxnt6qq%2Bfv9MsH2G5AlWAJCeujy1ckGLM9rLjYCG68FEFelQh8fprvwYs8t2Jzr4tbZ%2BWtMGJijsnErxj43ilIYITcrfSn3rFD98JGiZ8yROc%2FTR7GI24ObXD3dl8Il%2BiPCkR8q%2BstQjskiKEwtUdVDipxje8YW8B6naE7%2FUgiHtw6SivkOr36nzq%2FELMNuN%2FVb1VMe%2FyUdt5sTJ9JABZzQYhMZblEGTVbBR71bAEOGr0TA5RzU6ywlOyjrrHQo9rXGXR4FM5AsK%2BTyK2CBZOHJlTYSJwUYdPvwPAzgZh9WZpx0MqbdvXkANo7OpFaScA91lxvxRbC20As1OC76qgURA8mPWM%2Fh%2BOL9Qj5tYPMWf2WhnlfN9nSW9%2FpIG6v1sETJNsPrfUQ7x73OdxJ1QwI63xMWy3q%2Bd7aUb1y0n6Wluhz2bATCshZWyBjqmARJEuSzrXBBuogC5NkD5ywiTUtqehmvjc%2BwCdWDunhiU55fh4YHCKf%2BDEsFPWRuDoV9aorx4t7mvgTCPftMHNhV5Ti48VlG7y%2BTBnBPDXDyA0hFplxuKT9dGRuhW2eW2Wt33N7cn3rMUo38Rsek3NnNmYqgxPFr3nBEPogiMtey0MDGZtIbL%2FMZdslPzHF%2FQ5o1DzX%2FscDUXzl8pd93s7BM2o%2Fpps1E%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240515T232429Z&X-Amz-SignedHeaders=host&X-Amz-Expires=599&X-Amz-Credential=ASIA4XJFYOPESLT7OOWZ%2F20240515%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=84218c02b6c0c3ce81a066facbc89e6d0828d6cbbc743cbb232b45db4511475e",
    // ?   { onProgress: ({ fraction }) => console.log({ fraction }) }
    // ? ).then((res) => console.log({ res }));
  }

  showExportImageModal = () => {
    console.log(this.props);
    if (this.props && this.props.dispatch) {
      this.props.dispatch(toggleModal(EXPORT_IMAGE_ID));
      this.props.dispatch(startExportingImage());
    } else {
      console.error("dispatch no está disponible en props");
    }
  };
  getMap() {
    // retrieve kepler.gl store
    const { demo } = this.props;
    const { keplerGl } = demo;
    // retrieve current kepler.gl instance store
    const { map } = keplerGl;
    // create the config object
    return KeplerGlSchema.save(map);
  }

  downloadMap = () => {
    // create the config object
    const map = this.getMap();
    // save it as a json file
    const { urlMap } = this.state;
    console.log("urlMap", urlMap);
    if (urlMap === "") {
      let uuid = uuidv4();
      let uuidWithExtension = uuid.concat(".map.json");
      this.setState({ uuid: uuidWithExtension }, () => {
        downloadJsonFile(map, uuidWithExtension);
      });
      console.log("uuid", uuidWithExtension);
      this.changeStyleMap();
    } else {
      downloadJsonFile(map, urlMap);
    }
  };

  exportMap = async (formData) => {
    this.setLoading({ bool: true, msg: "Saving Map" });
    const map = this.getMap();
    const uuid = uuidv4();
    let uuidWithExtension = uuid.concat(".map.json");
    formData.append("item_uuid", uuid);
    console.log(uuid)
    const myblob = new Blob([JSON.stringify(map)], {
      type: "application/json",
    });
    const file = new File([myblob], uuidWithExtension, {
      type: "application/json",
    });
    formData.append("file", file);

    try {
      const response = await fetch(`${API_URL}/maps/upload`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        this.setState({ showAlert: true });
        this.setState({ alertMessage: "Map saved successfully" });
        this.setState({ alertResponse: "success" });
      } else {
        console.error("Failed to submit form");
        this.setState({ showAlert: true });
        this.setState({ alertMessage: "Error saving the map" });
        this.setState({ alertResponse: "error" });
      }
    } catch (error) {
      console.error("Error submitting form", error);
    } finally {
      this.changeStyleMap();
      this.setLoading({ bool: false, msg: "" });
    }
    changeStyleMap();
  };

  render() {
    // console.log(this.props);
    const { showAlert, progress, loading } = this.state;
    return (
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <Button onClick={this.downloadMap}>
          <DownloadIcon />
        </Button>
        <Button onClick={this.showExportImageModal}>
          <ImageIcon />
        </Button>
        <ExportMap onSubmit={this.exportMap} />

        <Dialog open={loading.bool} transitionDuration={300}>
          <DialogContent>
            <Box
              display="flex"
              alignItems="center"
              flexDirection={"column"}
              gap={2}
              height={"50%"}
              p={1}
              borderRadius={10}
            >
              <Lottie
                options={{ ...defaultOptions }}
                isClickToPauseDisabled
                height={50}
              />
              <Typography color="#7a7a7a">{loading.msg}</Typography>
              <Box width="100%">
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  sx={{
                    "& .MuiLinearProgress-bar": { backgroundColor: "Black" },
                  }}
                  style={{ backgroundColor: "#e0e0e0", width: "200px" }}
                />
              </Box>
              <Typography color="#7a7a7a">
                Tiempo restante: {this.calculateRemainingTime()}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => this.setState({ showAlert: false })}
        >
          <Alert
            onClose={() => this.setState({ showAlert: false })}
            severity={this.state.alertResponse}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {this.state.alertMessage}
          </Alert>
        </Snackbar>

        <AutoSizer>
          {({ height, width }) => (
            <KeplerGl
              mapboxApiAccessToken={MAPBOX_TOKEN}
              id="map"
              getState={(state) => ({
                ...state.demo.keplerGl,
              })}
              width={width}
              height={height}
              theme={theme}
              mapStyle={{ styleType: "positron" }}
              //cloudProviders={CLOUD_PROVIDERS}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

const dispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => state;

export default connect(mapStateToProps, dispatchToProps)(App);
